// @ts-nocheck third-party-js
/* eslint-disable */

import {
  CAPICOM_CERTIFICATE_FIND_SHA1_HASH,
} from '@core/plugins/cryptoPro/consts';

import {cryptoProCheck, CryptoProCheckResult} from "@core/plugins/cryptoPro/check";

var CADESCOM_CADES_BES = 1;
var CAPICOM_CURRENT_USER_STORE = 2;
var CAPICOM_MY_STORE = "My";
var CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;
var CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME = 1;
var CADESCOM_BASE64_TO_BINARY = 1;

function doCheck() {
  // Проверяем, работает ли File API
  if (window.FileReader) {
    // Браузер поддерживает File API.
  } else {
    alert("The File APIs are not fully supported in this browser.");
  }
  var fileReader = new FileReader();
  if (typeof (fileReader.readAsDataURL) !== "function") {
    alert("Method readAsDataURL() is not supported in FileReader.");
    return;
  }
}

export async function signFile(
  sFingerPrint: string,
  oFile: File,
  onPluginVersionChecked: (payload: CryptoProCheckResult) => void,
  onDocumentSigned: (payload: any) => void,
  onDocumentSignVerified: (payload: any) => void,
  onError: (e: any) => void,
) {

  const plugin = await window.cadesplugin.catch(e => e);
  console.log('plugin', plugin);
  if (plugin === 'Плагин недоступен') {
    onPluginVersionChecked({
      enabled: false,
      loaded: false,
      currentPluginVersion: null,
    });
    return;
  }

  const checkResult = await cryptoProCheck();
  onPluginVersionChecked(checkResult)
  if (!checkResult.enabled) {
    return;
  }
  function run() {
    cadesplugin.async_spawn(function* (args) {
      var oFReader = new FileReader();

      if (typeof (oFReader.readAsDataURL) != "function") {
        alert("Method readAsDataURL() is not supported in FileReader.");
        return;
      }

      oFReader.readAsDataURL(oFile);

      oFReader.onload = function (oFREvent) {
        cadesplugin.async_spawn(function* (args) {
          var header = ";base64,";
          var sFileData = oFREvent.target.result;
          var sBase64Data = sFileData.substr(sFileData.indexOf(header) + header.length);

          var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
          yield oStore.Open(CAPICOM_CURRENT_USER_STORE, CAPICOM_MY_STORE,
            CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED);

          var oStoreCerts = yield oStore.Certificates;
          var oCertificates = yield oStoreCerts.Find(
              CAPICOM_CERTIFICATE_FIND_SHA1_HASH, sFingerPrint);
          var certsCount = yield oCertificates.Count;
          if (certsCount === 0) {
            onError(`Сертификат не найден`)
            return;
          }
          var oCertificate = yield oCertificates.Item(1);
          var oSigner = yield cadesplugin.CreateObjectAsync("CAdESCOM.CPSigner");
          yield oSigner.propset_Certificate(oCertificate);
          yield oSigner.propset_CheckCertificate(true);

          var oSignedData = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
          yield oSignedData.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);
          yield oSignedData.propset_Content(sBase64Data);

          try {
            var sSignedMessage = yield oSignedData.SignCades(oSigner, CADESCOM_CADES_BES, true);
            onDocumentSigned();
          } catch (err) {
            onError(`Ошибка создания подписи ${cadesplugin.getLastError(err)}`);
            return;
          }

          yield oStore.Close();

          var oSignedData2 = yield cadesplugin.CreateObjectAsync("CAdESCOM.CadesSignedData");
          try {
            yield oSignedData2.propset_ContentEncoding(CADESCOM_BASE64_TO_BINARY);
            yield oSignedData2.propset_Content(sBase64Data);
            yield oSignedData2.VerifyCades(sSignedMessage, CADESCOM_CADES_BES, true);
            onDocumentSignVerified(sSignedMessage);
          } catch (err) {
            onError(`Ошибка проверки подписи: ${cadesplugin.getLastError(err)}`)
            return;
          }
        });
      };
    });
  }

  run();
}
