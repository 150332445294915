import {
  computed, ref, Ref, watch,
} from 'vue';
import { useUser } from '@/hooks/useUser';
import { EmployeeRole, useEmployees } from '@/hooks/useEmployees';
import { useSimpleFetch } from '@/hooks/useSimpleFetch';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useLocalI18n } from '@/hooks/useLocalI18n';

type UserSettingsSecureCryptoProModel = {
  certificate: string;
  owner: string;
  period: string;
  thumbprint: string;
  url: string;
  is_public: boolean;
}

export type UserSettingsSecureCryptoProResponse = ({
  id: number;
  company: number;
  is_default: boolean;
  is_public: boolean;
  is_readable: true;
  key: 'crypto_pro';
  owner: number|null;
  users: number[];
  data: Omit<UserSettingsSecureCryptoProModel, 'is_public'>;
});

type UserSettingsSecureCryptoProFormModel = UserSettingsSecureCryptoProModel & { id: number|null };

const responseToModel = (resp: UserSettingsSecureCryptoProResponse) => ({
  id: resp.id,
  certificate: resp.data.certificate,
  owner: resp.data.owner,
  period: resp.data.period,
  thumbprint: resp.data.thumbprint,
  url: resp.data.url,
  is_public: resp.is_public,
});

const getEmptyModelData = () => ({
  id: null,
  certificate: '',
  owner: '',
  period: '',
  thumbprint: '',
  url: '',
  is_public: false,
}) as UserSettingsSecureCryptoProFormModel;

export const useUserSettingsSecureCryptoProModel = (
  companyId: Ref<number>,
  useRemoteSign: Ref<boolean>,
) => {
  const { t } = useLocalI18n('exchange.integration.judgement.settingsForm');
  const { showToast } = useToast();
  const { user } = useUser();
  const { fetchEmployee } = useEmployees();
  const {
    data: employee,
    fetch: fetchEmployeeData,
    isLoading: employeeIsLoading,
  } = useSimpleFetch(() => fetchEmployee({
    id: user.value?.id as number,
    companyId: companyId.value,
  }));

  const models = ref<UserSettingsSecureCryptoProResponse[]>([]);
  const model = ref<UserSettingsSecureCryptoProFormModel>(getEmptyModelData());
  const currentModelData = computed(() => {
    const currentModel = models.value.find((m) => m.id === model.value.id);
    return currentModel;
  });
  const selectedModelId = computed<number|null>({
    get: () => model.value.id,
    set: (id: number|null) => {
      if (id === null) {
        return;
      }
      const selectedModel = models.value.find((m) => m.id === id);
      if (!selectedModel) {
        console.error(`Selected model not found (id ${id})`);
      }
      model.value = responseToModel(JSON.parse(JSON.stringify(selectedModel)));
    },
  });
  // поле для employee, показывать, только если есть model с is_public = true
  const usePublicModel = computed(() => models.value.filter((m) => !m.is_public).every(
    (m) => !m.is_default,
  ));

  const usePublicModelIsAvailable = computed(
    () => {
      const hasPersonalDefaultModel = models.value.some((m) => !m.is_public && m.is_default);
      if (!currentModelData.value) {
        return false;
      }
      return currentModelData.value.is_public && hasPersonalDefaultModel;
    },
  );

  const useModelAsDefault = computed(
    () => currentModelData.value?.is_default,
  );

  const useModelAsDefaultAvailable = computed(() => {
    if (!currentModelData.value) {
      return false;
    }
    if (currentModelData.value.is_public) {
      return false;
    }
    return true;
  });

  const onUseModelAsDefault = async (value: boolean) => {
    if (!currentModelData.value) {
      return;
    }
    isLoading.value = true;
    currentModelData.value.is_default = value;
    await commonLegacyApiRequest<void>({
      command: ApiCommand.updateCompanySettingsSecure,
      params: {
        company_id: companyId.value,
        id: model.value.id,
      },
      data: {
        set_default: value,
      },
    });

    console.log('onUseModelAsDefault', value);

    // если выбираем текущий личный сертификат как сертификат, используемый по умолчанию
    // - нужно проставить is_default = false всем остальным пользоватеским сертификатам
    if (value) {
      await Promise.all(models.value.filter(
        (m) => !m.is_public && m.id !== model.value.id,
      ).map((m) => {
        m.is_default = false;
        return commonLegacyApiRequest<void>({
          command: ApiCommand.updateCompanySettingsSecure,
          params: {
            company_id: companyId.value,
            id: m.id,
          },
          data: {
            set_default: false,
          },
        });
      }));
    }

    isLoading.value = false;
  };

  const resetModel = () => {
    model.value = getEmptyModelData();
  };
  const isLoading = ref(false);

  const userIsManager = computed(() => [EmployeeRole.manager, EmployeeRole.owner, EmployeeRole.manager].includes(
    employee.value?.employee_role,
  ));

  const canNotEditModelCertificateFields = computed(
    () => !userIsManager.value && model.value.is_public,
  );

  const canEditIsPublicField = computed(() => userIsManager.value);

  const fetchUserSettingsSecureModels = async () => {
    const response = await commonLegacyApiRequest<ListingResponse<UserSettingsSecureCryptoProResponse>>({
      command: ApiCommand.fetchCompanySettingsSecure,
      params: {
        key: 'crypto_pro',
        company_id: companyId.value,
        // ...(params.value.is_public
        //  ? { is_public: true } : { owner: user.value?.id as number }),
      },
    });

    if (!response.status) {
      return;
    }

    models.value = response.response.results;
    if (models.value.length) {
      model.value = responseToModel(
        JSON.parse(JSON.stringify(models.value[0])),
      );
    }
  };

  watch(companyId, async (id) => {
    isLoading.value = true;
    await Promise.all([
      fetchEmployeeData(),
      fetchUserSettingsSecureModels(),
    ]);
    isLoading.value = false;
  }, { immediate: true });

  const removeModel = async (modelId: number) => {
    const response = await commonLegacyApiRequest<void>({
      command: ApiCommand.deleteCompanySettingsSecure,
      params: {
        company_id: companyId.value,
        id: modelId,
      },
    });
    if (!response.status) {
      return;
    }
    if (model.value.id === modelId) {
      resetModel();
    }
    const modelListIndex = models.value.findIndex(
      (m) => m.id === modelId,
    );
    if (modelListIndex !== -1) {
      models.value.splice(modelListIndex, 1);
    }
    return response;
  };

  const saveModel = async () => {
    isLoading.value = true;
    const savedModel = JSON.parse(JSON.stringify(model.value)) as UserSettingsSecureCryptoProFormModel;

    if (model.value.id) {
      await commonLegacyApiRequest<void>({
        command: ApiCommand.deleteCompanySettingsSecure,
        params: {
          company_id: companyId.value,
          id: savedModel.id,
        },
      });
    }

    const payload = {
      is_readable: true,
      key: 'crypto_pro',
      source: savedModel,
    } as any;

    if (savedModel.is_public) {
      payload.users = [];
      payload.set_default = true;
      payload.set_public = true;
    } else {
      payload.users = [user.value?.id as number];
      payload.set_public = false;
      payload.set_default = true;
    }

    const response = await commonLegacyApiRequest<UserSettingsSecureCryptoProResponse>({
      command: ApiCommand.createCompanySettingsSecure,
      params: {
        company_id: companyId.value,
      },
      data: {
        is_readable: true,
        key: 'crypto_pro',
        ...payload,
      },
    });

    if (response.status) {
      if (savedModel.id) {
        model.value = responseToModel(JSON.parse(JSON.stringify(response.response)));
        const modelListIndex = models.value.findIndex(
          (m) => m.id === savedModel.id,
        );
        if (modelListIndex !== -1) {
          models.value.splice(modelListIndex, 1, response.response);
        }
      } else {
        model.value = responseToModel(JSON.parse(JSON.stringify(response.response)));
        models.value.push(response.response);
      }
    } else {
      showToast({
        level: IToastLevel.danger,
        label: 'pureLabel',
        params: {
          label: 'Ошибка',
        },
      });
    }

    isLoading.value = false;
  };

  const onUsePublicModel = async () => {
    const modelsToUpdate = models.value.filter(
      (m) => !m.is_public && m.is_default,
    );
    if (!modelsToUpdate.length) {
      return;
    }
    // usePublicModel.value = usePublic;
    isLoading.value = true;

    await Promise.all(
      modelsToUpdate.map(
        (m) => commonLegacyApiRequest<void>({
          command: ApiCommand.updateCompanySettingsSecure,
          params: {
            company_id: companyId.value,
            id: m.id,
          },
          data: {
            set_default: false,
          },
        }),
      ),
    );
    models.value.forEach((m) => {
      if (m.is_default) {
        m.is_default = false;
      }
    });
    isLoading.value = false;
  };

  const fields = computed(() => (([
    // !userIsManager.value && {
    //   key: 'use_public',
    //   type: ActiveFormFieldType.checkbox,
    //   options: {
    //     label: t('fields.use_public'),
    //   },
    // },
    {
      key: 'certificate',
      type: ActiveFormFieldType.input,
      options: {
        isDisabled: canNotEditModelCertificateFields,
      },
    },
    {
      key: 'owner',
      type: ActiveFormFieldType.input,
      options: {
        isDisabled: canNotEditModelCertificateFields,
      },
    },
    {
      key: 'period',
      type: ActiveFormFieldType.input,
      options: {
        hint: 'От 01.01.2021 до 01.01.2021',
        isDisabled: canNotEditModelCertificateFields,
      },
    },
    {
      key: 'thumbprint',
      type: ActiveFormFieldType.input,
      options: {
        isDisabled: canNotEditModelCertificateFields,
      },
    },
    {
      key: 'url',
      type: ActiveFormFieldType.input,
      options: {
        isDisabled: canNotEditModelCertificateFields,
      },
    },
    canEditIsPublicField.value && {
      key: 'is_public',
      type: ActiveFormFieldType.checkbox,
      options: {
        isDisabled: !useRemoteSign.value,
        label: t('fields.is_public'),
      },
    },
  ]).filter(Boolean) as Array<ActiveFormField<UserSettingsSecureCryptoProModel>>).map(
    (v) => ({ ...v, label: ['is_public', 'use_public'].includes(v.key) ? '' : t(`fields.${v.key}`) }),
  ) as Array<ActiveFormField<UserSettingsSecureCryptoProModel>>);

  return {
    models,
    model,
    resetModel,
    selectedModelId,
    usePublicModel,
    onUsePublicModel,
    usePublicModelIsAvailable,
    isLoading,
    removeModel,
    saveModel,
    fields,

    useModelAsDefault,
    onUseModelAsDefault,
    useModelAsDefaultAvailable,
  };
};
