import {
  computed, Ref, ref, watch,
} from 'vue';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand } from '@/store/modules/api';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { ImportTypeKey } from '@/pages/exchange/import/importTypes';

enum DocumentTypeBack {
  paymentOrder = 'pp', // Платежное поручение
  judgment = 'sp', // Судебное решение
  housebook = 'pd', // Домовая книга
  oosProtocol = 'oos' // Протокол ОСС (Общего Собрания Собственников) // fix it on back too
}

const map: Partial<Record<keyof typeof ImportTypeKey, DocumentTypeBack>> = {
  paymentOrder: DocumentTypeBack.paymentOrder,
  judgment: DocumentTypeBack.judgment,
  oosProtocol: DocumentTypeBack.oosProtocol,
  housebook: DocumentTypeBack.housebook,
};

export const useNeuroModels = (importType: Ref<ImportTypeKey>) => {

  const neuroModelsOptions = ref<{ value: string; label: string }[]>([]);
  const activeNeuroModel = ref<string>('');
  const isLoading = ref(false);
  const { showToast } = useToast();

  const fetchTemplates = async () => {
    const documentType = map[importType.value];
    if (!documentType) {
      return;
    }
    isLoading.value = true;
    const { status, response } = await commonLegacyApiRequest<{ model_tags: string[]}>({
      command: ApiCommand.fetchNeuroModelsTags,
      data: { filter: documentType },
    });
    if (!status) {
      await showToast({
        level: IToastLevel.danger,
        message: 'Ошибка при получении моделей нейросетей',
      });
      return;
    }
    if (!response.model_tags?.length) {
      await showToast({
        level: IToastLevel.warning,
        message: 'Модели нейросетей не найдены',
      });
      return;
    }
    neuroModelsOptions.value = response.model_tags.map((value) => ({
      value,
      label: value,
    }));
    activeNeuroModel.value = neuroModelsOptions.value[0].value;
    isLoading.value = false;
  };

  setTimeout(fetchTemplates);
  watch(importType, () => fetchTemplates());

  return {
    neuroModelsOptions,
    activeNeuroModel,
  };
};
