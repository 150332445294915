import { Certificate } from '@core/plugins/cryptoPro/getUserCertificates';
import { formatDateTime } from '@/utils/date';

export const renderCertLabel = (cert: Certificate) => (<div style="display: flex">
    <div>
        <span style="opacity: 0.6">Имя:</span> { cert.name }
        <br/>
        <span style="opacity: 0.6">Издатель:</span> { cert.issuerName }
        <span style="opacity: 0.6">ФИО владельца:</span> { cert.fio }
    </div>
    <div style="text-align: right">
        <span style="opacity: 0.6">Истекает:</span> { formatDateTime(new Date(cert.validTo)) }
    </div>
</div>);
