import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { useUnsubs } from '@/hooks/useUnsubs';
import {
  IConstructorReportTemplateColumnStyle,
} from '@/components/dialog/dialogs/constructorReportTemplateColumnStyle/ConstructorReportTemplateColumnStyle.vue';

export const useTemplateStyleModal = () => {
  const { showDialog } = useDialog();
  const { subscribeToSignalOnce } = useSignal();

  return async (
    columnName: string,
    payload: IConstructorReportTemplateColumnStyle['modelValue'],
    withWidth: boolean,
  ) => new Promise<IConstructorReportTemplateColumnStyle['modelValue']|null>((resolve) => {
    const propsToEdit = ['fill', 'font', 'alignment'] as IConstructorReportTemplateColumnStyle['propsToEdit'];
    if (withWidth) {
      propsToEdit.push('width');
    }
    showDialog({
      component: IDialogComponent.constructorReportTemplateColumnStyle,
      title: `${columnName} - оформление`,
      addInRoute: false,
      payload: {
        modelValue: payload ?? {},
        propsToEdit,
        onSubmit: (p) => resolve(p),
      },
    });
    subscribeToSignalOnce(SignalType.dialogClosed, ({ component }: any) => {
      if (component === IDialogComponent.constructorReportTemplateColumnStyle) {
        resolve(null);
      }
    });
  });
};
