import { Company, useCompanies } from '@/hooks/useCompanies';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { computed, ref } from 'vue';

export function useCompany() {
  const { companies } = useCompanies();
  const { company: defaultCompany } = useDefaultCompany();
  const company = ref<Company['id'] | null>(null);
  const companyModuleType = computed(() => {
    if (!company.value) {
      return 1;
    }
    const c = companies.value.find((c) => c.id === company.value) as Company;
    return c.module === 3 ? 1 : c.module;
  });
  return {
    company,
    defaultCompany,
    companyModuleType,
    companies,
  };
}
