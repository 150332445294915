import {
  ConstructorReportTemplateHeaderGroup,
  FetchTemplateTypesModel,
  FetchTemplateTypesResponse,
  ProductionType,
  TemplateType,
} from '@/hooks/useConstructor';
import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { formatListingRequest } from '@/components/activeTable/useActiveTable';
import { useApi2 } from '@/hooks/useApi2';

export type ConstructorReportTemplateHeaderStyleFont = Partial<{
  name: string;
  b: boolean;
  i: boolean;
  size: number;
}>;

export type ConstructorReportTemplateHeaderStyleAlignment = Partial<{
  horizontal: 'left'|'right'|'center';
  vertical: 'bottom'|'center'|'top';
}>

export type ConstructorReportTemplateHeaderStyleFill = {
  type: 'pattern';
  patternType: 'solid';
  fgColor: string;
}

export type ConstructorReportTemplateHeaderStyle = Partial<{
  font: ConstructorReportTemplateHeaderStyleFont;
  alignment: ConstructorReportTemplateHeaderStyleAlignment;
  fill: ConstructorReportTemplateHeaderStyleFill;
  no_duplicates: boolean;
  blank: boolean;
}>

export type ConstructorReportTemplateHeaders = Record<number, ConstructorReportTemplateHeaderStyle>;
export type ConstructorReportTemplateColumns = Record<number, { width: number }>;
export type ConstructorReportTemplateRows = Record<number, { height: number }>

export type ConstructorReportTemplate = {
  id: number;
  name: string;
  columns: Array<[string, string]>;
  renames?: Record<string, string>; // TODO убрать
  template_type: number;
  company_owner?: number;
  styles: {
    group_headers?: ConstructorReportTemplateHeaderGroup[];
    headers?: ConstructorReportTemplateHeaders;
    columns?: ConstructorReportTemplateColumns;
    rows?: ConstructorReportTemplateRows;
    [key: string]: any;
  };
  optional_fields?: any;
}

export const useConstructorReportTemplates = () => {
  const req = useApi2();

  return {
    templateType: {
      fetchList: (model: FetchTemplateTypesModel) => req<FetchTemplateTypesResponse>({
        command: ApiCommand.fetchConstructorReportTemplateTypes,
        params: formatListingRequest(model),
      }),
      createItem: (model: { name: string; production_type: ProductionType }) => req<TemplateType>({
        command: ApiCommand.createConstructorReportTemplateType,
        data: {
          name: model.name, description: model.name, production_type: model.production_type,
        },
      }),
      removeItem: (id: number) => req<any>({
        command: ApiCommand.removeConstructorReportTemplateType,
        params: { id },
      }),
    },
    template: {
      fetchList: (
        model: {
          template_type: number;
          company_owner?: number;
          production_type: ProductionType;
          company_id?: number;
        },
      ) => req<ListingResponse<ConstructorReportTemplate>>({
        command: ApiCommand.fetchConstructorReportTemplates,
        params: {
          ...model,
          limit: 1000,
        },
      }),
      fetchItem: (id: number) => req<ConstructorReportTemplate>({
        command: ApiCommand.fetchConstructorReportTemplate,
        params: { id },
      }),
      createItem: (model: Omit<ConstructorReportTemplate, 'id'>) => req<ConstructorReportTemplate>({
        command: ApiCommand.createConstructorReportTemplate,
        data: model,
      }),
      updateItem: (
        model: Partial<ConstructorReportTemplate> & { id: number },
      ) => req<ConstructorReportTemplate>({
        command: ApiCommand.updateConstructorReportTemplate,
        params: {
          id: model.id,
        },
        data: model,
      }),
      removeItem: (id: number) => req<any>({
        command: ApiCommand.removeConstructorReportTemplate,
        params: {
          id,
        },
      }),
    },
  };
};
