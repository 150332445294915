import { useApi } from '@/hooks/useApi';
import { ApiCommand } from '@/store/modules/api';

export type ClientApiKeyModel = {
  company_id: number;
}

export type ClientApiKeyResponse = {
  api_key: string;
}

export const useClientApiKey = () => {
  const api = useApi();

  return {
    fetch: (model: ClientApiKeyModel) => api<ClientApiKeyResponse>({
      command: ApiCommand.fetchClientApiKey,
      params: model,
    }),
    generate: (model: ClientApiKeyModel) => api<ClientApiKeyResponse>({
      command: ApiCommand.generateClientApiKey,
      params: model,
    }),
  };
};
