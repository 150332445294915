import { computed, Ref } from 'vue';
import { DataType, ImportTypeKey } from '@/pages/exchange/import/importTypes';
import { RouteRecordRaw } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { PermissionsModel } from '@/hooks/useCompanyPermissionsApi';

export function useImportTypes(
  permissionsMap: Ref<PermissionsModel>,
) {
  const { t } = useI18n();

  const importTypes = computed<Array<DataType<ImportTypeKey>>>(() => ((([
    permissionsMap.value.pretrial === 'write' && {
      key: ImportTypeKey.pretrial,
    },
    permissionsMap.value.judicial === 'write' && {
      key: ImportTypeKey.judicial,
    },
    permissionsMap.value.executive === 'write' && {
      key: ImportTypeKey.executive,
    },
  ]).filter(Boolean) as { key: ImportTypeKey}[]).map((type) => ({
    ...type,
    label: t(`exchange.dataType.${type.key}`),
    to: {
      params: {
        dataType: type.key,
      },
    } as Partial<RouteRecordRaw>,
  }))));

  return { importTypes };
}
