import { usePermissionsMap } from '@/pages/exchange/usePermissionsMap';
import {
  computed, provide, ref, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useImportTypes } from '@/pages/export/useImportTypes';
import { getAwaitFrame } from '@/utils/window';
import { CompanyIdKey, CompanyModuleTypeKey, RegionCodeKey } from '@/symbols';
import { useCompany } from '@/pages/exchange/useCompany';
import { ProductionType } from '@/hooks/useConstructor';

export function useExportMain() {
  const { t } = useI18n();
  const dataType = computed<ProductionType>(() => {
    const dataType = currentRoute.value.params.dataType;
    if (!dataType) {
      routerPush({
        params: { dataType: 'judicial' },
      });
    }
    return dataType as ProductionType;
  });
  const {
    company, defaultCompany, companies, companyModuleType,
  } = useCompany();
  const { currentRoute, push: routerPush } = useRouter();
  const { fetchPermissions, permissionsMap } = usePermissionsMap(company);

  const { importTypes } = useImportTypes(permissionsMap);

  provide(CompanyIdKey, company);
  provide(CompanyModuleTypeKey, companyModuleType);

  watch(defaultCompany, (newCompany) => {
    if (!company.value) {
      company.value = newCompany?.id || null;
    }
  }, {
    immediate: true,
  });

  watch(
    company,
    getAwaitFrame((companyId) => {
      fetchPermissions();
    }), {
      immediate: true,
    },
  );

  const withYear = ref(true);

  return {
    withYear,
    t,

    companies,
    company,

    importTypes,
    dataType,
    companyModuleType,
    currentRoute,
    permissionsMap,
  };
}
