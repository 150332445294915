import { usePermissionsMap } from '@/pages/exchange/usePermissionsMap';
import { useTabs } from '@/pages/exchange/useTabs';
import {
  computed, provide, ref, watch,
} from 'vue';
import { ITabs } from '@/components/tabs/useTabs';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ImportTypeKey } from '@/pages/exchange/import/importTypes';
import { CompanyModuleType } from '@/pages/debtors/_module/useDebtorsActions';
import { useImportKeys } from '@/pages/exchange/import/useImportKeys';
import { getAwaitFrame } from '@/utils/window';
import { useRegions } from '@/pages/exchange/useRegions';
import { CompanyIdKey, CompanyModuleTypeKey, RegionCodeKey } from '@/symbols';
import { useCompany } from '@/pages/exchange/useCompany';

export function useExchangeMain() {
  const { t } = useI18n();
  const dataType = computed<ImportTypeKey>(() => (
    (currentRoute.value.params.dataType || 'instruction') as ImportTypeKey
  ));
  const {
    company, defaultCompany, companies, companyModuleType,
  } = useCompany();
  const { currentRoute, push: routerPush } = useRouter();
  const { fetchPermissions, permissionsMap } = usePermissionsMap(company);
  const { tabsGetter } = useTabs(company, permissionsMap);
  const tabs = computed<ITabs['tabs']>(tabsGetter);
  const { importTypes } = useImportKeys(permissionsMap, companyModuleType);
  const {
    region,
    regionFilter,
    fetchDefaultRegion,
    setRegion,
    currentRegionCode,
    regions,
  } = useRegions(company);

  provide(CompanyIdKey, company);
  provide(CompanyModuleTypeKey, companyModuleType);
  provide(RegionCodeKey, currentRegionCode);
  provide('regionId', region);

  const regionSelectorDisabled = computed(
    () => companyModuleType.value === CompanyModuleType.Vehicles
      && currentRoute.value.name === 'exchange-import',
  );

  const sidebarIsHidden = computed(
    () => (currentRoute.value.name as string).includes('exchange-integration')
      || (currentRoute.value.name as string).includes('exchange-template'),
  );

  watch(defaultCompany, (newCompany) => {
    if (!company.value) {
      company.value = newCompany?.id || null;
    }
  }, {
    immediate: true,
  });

  watch(
    [company, regionSelectorDisabled],
    getAwaitFrame(([companyId, isDisabled]) => {
      fetchPermissions();
      if (!isDisabled) {
        fetchDefaultRegion(companyId);
      } else {
        region.value = null;
      }
    }), {
      immediate: true,
    },
  );

  watch(importTypes, (arr) => {
    if (!arr.find(({ key }) => key === dataType.value)) {
      routerPush({
        params: {
          dataType: ImportTypeKey.instruction,
        },
      });
    }
  });

  const withYear = ref(true);

  return {
    withYear,
    t,

    tabs,
    companies,
    company,
    regionFilter,
    regions,
    setRegion,
    region,

    importTypes,
    dataType,
    companyModuleType,
    currentRoute,
    regionSelectorDisabled,
    permissionsMap,
    sidebarIsHidden,
  };
}
