import { getRoutePermissionChecker } from '@/hooks/useProtectedDefaultCompany';
import { ITabs } from '@/components/tabs/useTabs';
import { Ref } from 'vue';
import { PermissionsModel } from '@/hooks/useCompanyPermissionsApi';
import { useI18n } from 'vue-i18n';

export function useTabs(company: Ref<number | null>, permissionsMap: Ref<PermissionsModel>) {
  const { t } = useI18n();
  return {
    tabsGetter: () => {
      const permissionChecker = getRoutePermissionChecker(permissionsMap.value);
      return ([
        {
          key: 'import',
          id: 'page_exchange_import_tab',
          label: t('exchange.import.title'),
          icon: 'download',
          to: {
            name: 'exchange-import',
          },
        },
        {
          key: 'integration',
          id: 'page_exchange_integration_tab',
          label: t('exchange.integration.title'),
          icon: 'integration',
          to: {
            name: 'exchange-integration',
          },
        },
        permissionChecker('exchange-integration') && {
          key: 'templates',
          id: 'page_exchange_templates_tab',
          label: t('exchange.templates.title'),
          icon: 'documents',
          to: {
            name: 'exchange-templates',
          },
        },
      ]).filter(Boolean) as ITabs['tabs'];
    },
  };
}
