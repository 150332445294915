import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { Ref } from 'vue';
import { ExchangeImportRequest } from '@/pages/exchange/import/useExchangeImport';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand } from '@/store/modules/api';
import {
  DocumentParsingTemplate,
  getEmptyParsingTemplate,
  useDocumentParsingTemplatesApi,
} from '@/hooks/useDocumentParsingTemplatesApi';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { ProductionType } from '@/hooks/useConstructor';

export function useEditImported(
  companyId: Ref<number>,
) {
  const { showDialog } = useDialog();
  const { showToast } = useToast();
  const api = useDocumentParsingTemplatesApi();

  type Response = {
    status: boolean;
    response: {
      count: number; // всего строк
      rows: unknown[]; // первые n строк
    };
  }

  async function openEditLayoutDialog(
    model: Omit<ExchangeImportRequest, 'template_id'> & { template_id?: number },
    templates: Ref<DocumentParsingTemplate[]>,
    productionType: ProductionType,
  ) {
    const hideToast = await showToast({
      message: 'pure',
      label: 'pureLabel',
      level: IToastLevel.success,
      params: {
        label: 'Ожидайте',
        message: 'Выполняется обработка файла',
      },
      duration: 20000,
    });

    const file = model.files[model.files.length - 1];
    const result: Response = await commonLegacyApiRequest({
      command: ApiCommand.parseDocument,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        filename: file.name,
      },
      data: { file: file.fileRaw },
    });

    if (!result.response?.rows) {
      await hideToast();
      await showToast({
        label: 'pureLabel',
        message: 'pure',
        level: IToastLevel.danger,
        params: {
          label: 'Ошибка обработки файла',
          message: 'Проверьте, что файл в формате xlsx',
        },
        duration: 8000,
      });
      return;
    }

    let newTemplate;

    if (!model.template_id) {
      const template = getEmptyParsingTemplate(productionType);
      const result = await api.createTemplate({
        ...template,
        title: `Новый шаблон ${new Date().toLocaleString()}`,
        company_id: companyId.value,
        columns_set: [],
      });
      newTemplate = result.response;
      model.template_id = result.response.id;
      model.isFreshTemplate = true;
    }

    newTemplate && templates.value.push(newTemplate);

    await hideToast();
    await showDialog({
      component: IDialogComponent.editImportedFileLayout,
      title: '',
      payload: {
        model: {
          ...model,
          template_id: model.template_id!,
        },
        companyId: companyId.value,
        data: result.response.rows?.slice(0, 6),
        templates,
      },
      addInRoute: false,
    });
  }

  return {
    openEditLayoutDialog,
  };
}
