import {
  computed, Ref, ref, watch,
} from 'vue';
import { ProductionType } from '@/hooks/useConstructor';
import { Company } from '@/hooks/useCompanies';
import { ExchangeImportMode } from '@/pages/exchange/import/useExchangeImport';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { ApiCommand, ApiResponse, ListingResponse } from '@/store/modules/api';
import { IToastLevel, useToast } from '@/hooks/useToast';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { DocumentParsingTemplate } from '@/hooks/useDocumentParsingTemplatesApi';

export type ExchangeImportTemplateType = 'company'|'common'

export type ExchangeImportTemplatesModel = {
  company_id: Company['id'];
  production_type: ProductionType|null;
  mode: ExchangeImportMode;
  type?: ExchangeImportTemplateType;
  limit: number;
  offset: number;
}

export type ExchangeImportTemplate = {
  id: number;
  title: string;
  rows_offset: number;
  production_type: ProductionType;
  type: ExchangeImportTemplateType;
  mode: ExchangeImportMode;
  is_default: boolean;
  settings?: [{
    is_active: boolean;
  }?];
  disable_calculations: boolean;
}

export type ExchangeImportTemplateUpdateModel = { id: number } & Partial<ExchangeImportTemplate>;

export const useExchangeImportTemplates = (params: Ref<ExchangeImportTemplatesModel>) => {

  const { t } = useLocalI18n('exchange.import.templates');

  const templ = ref<DocumentParsingTemplate[]>([]);
  const templates = computed(() => templ.value || []);
  const isLoading = ref(false);
  const { showToast } = useToast();
  const activeTemplate = ref<number|null>(null);

  const fetchTemplates = async () => {
    if (!params.value.production_type) {
      return;
    }

    isLoading.value = true;

    const { status, response } = await commonLegacyApiRequest<ListingResponse<DocumentParsingTemplate>>({
      command: ApiCommand.fetchDocumentParsingTemplates,
      params: params.value,
    });
    if (!status) {
      await showToast({
        level: IToastLevel.danger,
        message: t('toast.fetchError'),
      });
      return;
    }

    templ.value = (
      !templ.value.length
        ? response.results
        : templ.value[0].production_type === params.value.production_type
          ? templ.value
            .concat(response.results)
            .filter((item, index, arr) => (
              index === arr.findIndex((t) => (
                t.id === item.id))))
          : response.results
    );

    // @ts-ignore
    templ.value = templ.value.concat([{ id: null, title: t('create') }]);

    const currentActive = templ.value.find((t) => t.is_default);

    if (currentActive) {
      activeTemplate.value = currentActive.id;
    }

    if (response.count > params.value.limit + params.value.offset) {
      params.value.offset = params.value.limit;
      params.value.limit = response.count - params.value.limit;
    }

    isLoading.value = false;
  };

  watch(params, fetchTemplates, { immediate: true });

  const updateTemplate = (
    model: ExchangeImportTemplateUpdateModel,
  ) => {
    const currentTemplateIndex = templ.value?.findIndex((t) => t.id === model.id);
    if (currentTemplateIndex !== -1 && currentTemplateIndex !== undefined) {
      (templ.value as ExchangeImportTemplate[])[currentTemplateIndex] = {
        ...((templ.value as ExchangeImportTemplate[])[currentTemplateIndex]),
        ...(model),
      };
    }
    return commonLegacyApiRequest<ApiResponse<ExchangeImportTemplate>>({
      command: ApiCommand.updateConstructorParsingTemplate,
      params: { id: model.id },
      data: model,
    }).then((response) => {
      if (!response.status) return response;
      const currentTemplateIndex = templ.value?.findIndex((t) => t.id === model.id);
      if (currentTemplateIndex !== -1 && currentTemplateIndex !== undefined) {
        (templ.value as ExchangeImportTemplate[])[currentTemplateIndex] = {
          ...((templ.value as ExchangeImportTemplate[])[currentTemplateIndex]),
          ...(response.response),
        };
      }
      return response;
    });
  };

  return {
    templates,
    activeTemplate,
    updateTemplate,
  };
};
