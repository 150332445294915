import { computed, Ref } from 'vue';
import { ProductionType, TemplateType } from '@/hooks/useConstructor';
import { useModelDialog } from '@/hooks/useModelDialog';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import {
  ConstructorReportTemplate,
  useConstructorReportTemplates,
} from '@/hooks/useConstructorReportTemplates';
import { ApiResponseError } from '@/service/api';

type Model = { name: string };

export const useCreateTemplateModal = (
  companyId: Ref<number>,
) => {
  const api = useConstructorReportTemplates();
  const modal = useModelDialog<
    Model,
    ConstructorReportTemplate,
    Pick<ConstructorReportTemplate, 'template_type'|'columns'|'styles'>
    >({
      fields: [
        {
          key: 'name',
          field: 'name',
          type: ActiveFormFieldType.input,
          options: {
            label: 'Имя',
          },
        },
      ] as Array<ActiveFormField<Model>>,
      onModelUpdate: async (model, payload) => {
        if (!model.name) {
          return {
            status: false,
            response: {
              name: ['Введите имя'],
            },
          } as ApiResponseError;
        }
        return api.template.createItem({
          name: model.name,
          company_owner: companyId.value,
          columns: payload.columns,
          template_type: payload.template_type,
          styles: payload.styles,
        });
      },
      title: 'Новый шаблон',
    });

  return modal;
};
