import {
  computed, onMounted, ref, Ref,
} from 'vue';
import { IToastLevel, IToastProgressbar, useToast } from '@/hooks/useToast';
import { commonLegacyApiRequest } from '@urrobot/core/service/commonService';
import { ApiCommand, ListingResponse } from '@/store/modules/api';
import { dateToApiDate } from '@/utils/date';
import { unwrapListingApiResponse } from '@/service/api';
import { openAuthProtectedFile } from '@/utils/fileUrl';
import { useSocket } from '@/hooks/useSocket';
import { useUnsubs } from '@/hooks/useUnsubs';
import { getSocketMessageTaskID, isDataFileEvent } from '@/types/socket';
import { CustomPeriodicTask, PeriodicTaskSchedule, useSchedule } from '@/hooks/useSchedule';
import { IDialogComponent, useDialog } from '@/hooks/useDialog';
import { t } from '@/i18n';

type DocumentsExtractFromEgrnModel = {
  date_from: string;
  date_to: string;
  file: string;
}

type DocumentsExtractFromEgrnRenderModel = {
  id: number;
  file: string;
  company: number;
  date_from: string;
  date_to: string;
}

type DocumentsExtractFromEgrnRenderResponse = DocumentsExtractFromEgrnRenderModel & { uuid: string };

type Model = {
  datePeriod: [null, null]|[Date, Date];
  downloadPreparedArchive: boolean;
  citySettlement: boolean;
  auto: boolean;
  email: string|null;
  schedule: PeriodicTaskSchedule|null;
  personal_account: string|null;
  owner_name: string|null;
  cadnum: string|null;
  has_owner: boolean;
  has_no_owner: boolean;
}

const TASK_NAME = 'documents.auto_send_egrn_archive';

export const useDocumentsExtractFromEgrn = (
  companyId: Ref<number>,
) => {
  const { showDialog } = useDialog();
  const { showToast } = useToast();
  const { subscribe } = useSocket();
  const { sub, unsub } = useUnsubs();
  const scheduleApi = useSchedule<CustomPeriodicTask>(companyId);

  const isPending = ref(false);
  const popoverRef = ref<{ hide:() => void }>();
  const popoverIsVisible = ref(false);
  const model = ref<Model>({
    datePeriod: [null, null],
    downloadPreparedArchive: true,
    citySettlement: false,
    auto: false,
    schedule: null,
    email: null,
    personal_account: null,
    owner_name: null,
    cadnum: null,
    has_owner: true,
    has_no_owner: true,
  });

  const filenameParts = ref<string[]>([]);

  const updateFilename = (name: string) => {
    if (filenameParts.value.find((item) => item === name)) {
      filenameParts.value = filenameParts.value.filter((item) => item !== name);
    } else {
      filenameParts.value.push(name);
    }
  };

  const getOwnerFilter = () => {
    if (model.value.has_no_owner && model.value.has_owner) {
      return {};
    }
    if (!model.value.has_no_owner && !model.value.has_owner) {
      return {};
    }
    if (model.value.has_owner) {
      return { has_estate_objects: true };
    }
    return { has_estate_objects: false };
  };

  const progressMax = ref(0);
  const progressCurrent = ref(0);
  const toastProgresses = computed(() => (progressMax.value ? [{
    key: 'progress',
    label: 'Прогресс',
    max: progressMax.value,
    current: progressCurrent.value,
  } as IToastProgressbar] : []));

  const submit = async () => {
    if (isPending.value) {
      return;
    }

    const [date_from, date_to] = model.value.datePeriod.map((d) => (d ? dateToApiDate(d as Date) : d));
    const periodIsEmpty = (!date_from && !date_to);

    if (!(filenameParts.value?.length > 0)) {
      showToast({
        label: 'Ошибка',
        message: 'Выберите лицевой счет/ФИО/кадастровый номер',
        level: IToastLevel.danger,
      });
      return;
    }

    if (periodIsEmpty ? false : (!date_from || !date_to)) {
      showToast({
        label: 'Ошибка',
        message: 'Выберите полный период',
        level: IToastLevel.danger,
      });
      return;
    }

    if (model.value.auto && (!model.value.schedule || !model.value.email)) {
      showToast({
        label: 'Ошибка',
        message: 'Введите email получается и период',
        level: IToastLevel.danger,
      });
      return;
    }

    if (model.value.auto) {
      const tasksToDelete = await scheduleApi.task.getList({ name: TASK_NAME });
      if (tasksToDelete.status) {
        if (tasksToDelete.response.length) {
          await Promise.all(
            tasksToDelete.response.map((task) => scheduleApi.task.delete(task.id)),
          );
        }
      }
      const response = await scheduleApi.task.create({
        name: TASK_NAME,
        schedule: model.value.schedule!,
        extra: {
          company_id: companyId.value,
          ...(!periodIsEmpty ? { date_from, date_to } : {}),
          ...(model.value.citySettlement ? { city_settlement: true } : {}),
          email: model.value.email,
          filename_parts: filenameParts.value,
          ...getOwnerFilter(),
        },
      });
      if (response.status) {
        showToast({
          label: 'Задача добавлена в расписание',
          level: IToastLevel.success,
        });
      }
      popoverIsVisible.value = false;
    }

    // если архив за этот период уже есть - отдаем его
    if (model.value.downloadPreparedArchive) {
      const listResponse = await commonLegacyApiRequest<ListingResponse<DocumentsExtractFromEgrnRenderModel>>({
        command: ApiCommand.documentsExtractFromEgrnFetchList,
        params: {
          company: companyId.value,
          date_from,
          date_to,
          filename_parts: filenameParts.value,
          ...getOwnerFilter(),
          limit: 1000,
          offset: 0,
          ...(!periodIsEmpty ? { date_from, date_to } : {}),
          ...(model.value.citySettlement ? { city_settlement: true } : {}),
        },
      }).then(unwrapListingApiResponse);

      if (!listResponse.status) {
        isPending.value = false;
        return;
      }

      if (periodIsEmpty) {
        if (listResponse.response.length) {
          const res = listResponse.response.filter(
            ({ date_from, date_to }) => !date_from && !date_to,
          );
          if (res.length) {
            const { file } = res[0];
            showDialog({
              component: IDialogComponent.file,
              addInRoute: false,
              payload: {
                title: t('exchange.export.dialog.title'),
                url: file,
                withPreview: false,
                withCopy: true,
              },
            });
            isPending.value = false;
            return;
          }
        }
      } else {
        if (listResponse.response.length) {
          const { file } = listResponse.response[0];
          showDialog({
            component: IDialogComponent.file,
            addInRoute: false,
            payload: {
              title: t('exchange.export.dialog.title'),
              url: file,
              withPreview: false,
              withCopy: true,
            },
          });
          isPending.value = false;
          return;
        }
      }
    }

    const hideToast = await showToast({
      label: 'Загрузка...',
      level: IToastLevel.info,
      duration: null,
      progressbars: toastProgresses,
    });
    sub(hideToast);

    const renderResponse = await commonLegacyApiRequest<DocumentsExtractFromEgrnRenderResponse>({
      command: ApiCommand.documentsExtractFromEgrnRender,
      data: {
        company: companyId.value,
        filename_parts: filenameParts.value,
        ...getOwnerFilter(),
        ...(!periodIsEmpty ? { date_from, date_to } : {}),
        ...(model.value.citySettlement ? { city_settlement: true } : {}),
      },
    });

    if (!renderResponse.status) {
      isPending.value = false;
      unsub(hideToast);
      return;
    }

    const { uuid } = renderResponse.response;

    const unsubSocket = await subscribe({
      condition: (payload) => isDataFileEvent(payload)
        && getSocketMessageTaskID(payload) === uuid,
      handler: (payload) => {
        if (payload.data.progress) {
          progressCurrent.value = payload.data.progress.step;
          progressMax.value = payload.data.progress.total;
        }
        if (payload.data.done) {
          const url = payload.data.obj?.url;
          unsub(hideToast);
          unsub(unsubSocket);
          if (url) {
            showDialog({
              component: IDialogComponent.file,
              addInRoute: false,
              payload: {
                title: t('exchange.export.dialog.title'),
                url,
                withPreview: false,
                withCopy: true,
              },
            });
          } else {
            showToast({
              level: IToastLevel.danger,
              label: 'Ошибка загрузки файла',
            });
          }
        }
      },
    });
    sub(unsubSocket);

    popoverIsVisible.value = false;
  };

  const fetchSchedule = async () => {
    const response = await scheduleApi.task.getFirst({
      name: TASK_NAME,
    });
    if (!response.status) {
      return;
    }
    const task = response.response as CustomPeriodicTask<any>;
    if (!task) {
      return;
    }
    model.value.email = task.extra.email;
    model.value.schedule = task.schedule;
    model.value.citySettlement = task.extra.citySettlement;
    model.value.auto = true;
    const { date_from, date_to } = task.extra;
    if (date_from && date_to) {
      model.value.datePeriod = [new Date(date_from), new Date(date_to)];
    }
  };

  onMounted(() => {
    fetchSchedule();
  });

  const onUpdateModelAuto = (value: boolean) => {
    if (!value) {
      model.value.email = null;
      model.value.schedule = null;
    }
    model.value.auto = value;
  };

  return {
    popoverRef,
    popoverIsVisible,
    model,
    submit,
    onUpdateModelAuto,
    updateFilename,
  };
};
