import { computed, Ref } from 'vue';
import { ProductionType, TemplateType } from '@/hooks/useConstructor';
import { useModelDialog } from '@/hooks/useModelDialog';
import { ActiveFormField, ActiveFormFieldType } from '@/hooks/useActiveForm';
import { useConstructorReportTemplates } from '@/hooks/useConstructorReportTemplates';
import { ApiResponseError } from '@/service/api';

type Model = { name: string };

export const useCreateTemplateTypeModal = (productionType: Ref<ProductionType>) => {
  const api = useConstructorReportTemplates();
  const modal = useModelDialog<Model, TemplateType>({
    fields: [
      {
        key: 'name',
        field: 'name',
        type: ActiveFormFieldType.input,
        options: {
          label: 'Имя',
        },
      },
    ] as Array<ActiveFormField<Model>>,
    onModelUpdate: async (model) => {
      if (!model.name) {
        return {
          status: false,
          response: {
            name: ['Введите имя'],
          },
        } as ApiResponseError;
      }
      return api.templateType.createItem({
        production_type: productionType.value,
        name: model.name,
      });
    },
    title: 'Новый шаблон',
  });

  return modal;
};
